@font-face {
	font-family: 'Roboto-Bold';
	src: url(./Roboto-Bold.ttf) format("opentype");
}

@font-face {
	font-family: 'Roboto-Medium';
	src: url(./Roboto-Medium.ttf) format("opentype");
}

@font-face {
	font-family: 'Roboto-Regular';
	src: url(./Roboto-Regular.ttf) format("opentype");
}
