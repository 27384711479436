/* You can add global styles to this file, and also import other style files */
@import "./assets/fonts/roboto/font.css";
@import "./assets/fonts/material/font.css";

:root {
	--notification-and-control-color: rgb(236, 91, 91);
}

html {
	height: 100%;
	font-size: 16px;
}

body {
	height: 100%;
	margin: 0;
	color: rgb(40, 52, 74);

	button:disabled,
	button[disabled] {
		&:hover {
			opacity: 1;
		}

		background: var(--disabled-color) !important;
		cursor: default;
	}

	button {
		cursor: pointer;
		outline: none;

		&:hover {
			opacity: 0.8;
		}
	}

	.required:after {
		content: "*";
		display: inline-block;
		margin-left: 2px;
		color: rgb(255, 0, 0);
		font-size: 15px;
	}

	input[type="time"] {
		padding: 4px 10px;
		font-size: 14px;
		line-height: 20px;
		color: rgba(40, 52, 74, 0.9);
		background: rgb(255, 255, 255);
		border-radius: 2px;
		border: 1px solid rgb(223, 223, 223);
		box-sizing: border-box;

		&:disabled {
			color: #676767;
			background-color: #fafafa;
		}

		&:focus-visible {
			outline-offset: -2px !important;
		}
	}
}

/* полоса прокрутки (скроллбар) */
::-webkit-scrollbar {
	width: 5px;
	height: 5px;
}

/* ползунок скроллбара */
::-webkit-scrollbar-thumb {
	background-color: gray;
	border-radius: 9em;
}

* {
	scrollbar-width: thin;
}
