:root {
	--active-color: #0bb494;
	--active-color-hover: rgba(86, 197, 175, 0.2);
	--accent-color: rgb(11, 180, 148);
	--disabled-color: #a6ded3;
	--password-color: #0bb494;
	--control-font-family: Roboto-Medium, sans-serif;
	--control-font-family-bold: Roboto-Bold, sans-serif;
	--control-font-family-regual: Roboto-Regular, sans-serif;
}

body {
	font-family: "Roboto-Regular", sans-serif;
}

.logo {
	height: 25px;
	max-width: 100px;
	margin: 12px 30px 11px 20px;

	&:focus {
		outline: none;
	}

	&:hover {
		cursor: pointer;
	}
}
